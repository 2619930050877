import React, { useContext } from 'react';
import { LanguageContext } from '../context/LanguageContex';

function About() {
    const { translations } = useContext(LanguageContext);

    return (
        <>
            <div className="container-fluid py-6" id="about">
                <div className="container" style={{paddingBottom:30,paddingTop:10}}>
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
                            {/* Carousel Start */}
                            <div id="aboutCarousel" className="carousel slide" data-bs-ride="carousel" style={{ width: '100%', height: 300 }}>
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={1} aria-label="Slide 2" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={2} aria-label="Slide 3" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={3} aria-label="Slide 4" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={4} aria-label="Slide 5" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={5} aria-label="Slide 6" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={6} aria-label="Slide 7" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={7} aria-label="Slide 8" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={8} aria-label="Slide 9" />
                                    <button type="button" data-bs-target="#aboutCarousel" data-bs-slide-to={9} aria-label="Slide 10" />
                                </div>
                                <div className="carousel-inner" style={{ width: '100%', height: '100%' }}>
                                    <div className="carousel-item active">
                                        <img src="img/carousel/cr-5.jpg" className="d-block w-100 img-fluid rounded" alt="Image 1" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-7.jpg" className="d-block w-100 img-fluid rounded" alt="Image 2" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-10.jpg" className="d-block w-100 img-fluid rounded" alt="Image 3" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-8.jpg" className="d-block w-100 img-fluid rounded" alt="Image 4" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-1.jpg" className="d-block w-100 img-fluid rounded" alt="Image 5" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-6.jpg" className="d-block w-100 img-fluid rounded" alt="Image 6" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-2.jpg" className="d-block w-100 img-fluid rounded" alt="Image 7" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-4.jpg" className="d-block w-100 img-fluid rounded" alt="Image 8" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-9.jpg" className="d-block w-100 img-fluid rounded" alt="Image 9" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="img/carousel/cr-3.jpg" className="d-block w-100 img-fluid rounded" alt="Image 10" style={{ objectFit: 'cover', width: '100%', height: 300 }} />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#aboutCarousel" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#aboutCarousel" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true" />
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            {/* Carousel End */}
                        </div>
                        <div className="col-lg-7 wow bounceInUp" data-wow-delay="0.3s">
                            <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">{translations.about}</small>
                            <p className="mb-4 lang-text" data-key="aboutText">{translations.aboutText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;

