import React, { useState, useContext } from 'react';
import map from '../assets/gwave-map.png';
import { CountryContext } from '../context/CountryContext';

function Card({ imgSrc, titleKey, subtitleKey, flagSrc, delay }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const { translations } = useContext(CountryContext);

    return (
        <div className={`col-lg-2 col-md-3 col-sm-4 wow bounceInUp`} data-wow-delay={delay}>
            <div className="card bg-white position-relative" style={{ width: '100%', height: 250 }}>
                <img src={imgSrc} className="card-img-top" alt={`Card Image ${translations[titleKey]}`} style={{ objectFit: 'cover', height: 150 }} />
                <div className="card-body">
                    <h5 className="card-title">{translations[titleKey]}</h5>
                    <p
                        className="card-subtitle mb-2 text-muted"
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
                        // onMouseEnter={() => setShowTooltip(true)}
                        // onMouseLeave={() => setShowTooltip(false)}
                    >
                        {translations[subtitleKey]}
                    </p>
                    {/* <div className={`custom-tooltip ${showTooltip ? 'show' : ''}`}>{translations[subtitleKey]}</div> */}
                </div>
                <img src={flagSrc} className="rounded-circle position-absolute" style={{ width: 20, top: 10, right: 10 }} alt="Small Icon" />
            </div>
        </div>
    );
}

function Availability() {
    const { translations } = useContext(CountryContext);
    const cardsData = [
        { imgSrc: 'img/es-land.jpg', titleKey: 'Spain', subtitleKey: 'SpainDesc', flagSrc: 'img/spainFlag.jpg', delay: '0.2s' },
        { imgSrc: 'img/moroccoLand.png', titleKey: 'Morocco', subtitleKey: 'MoroccoDesc', flagSrc: 'img/moroccoFlag.png', delay: '0.3s' },
        { imgSrc: 'img/egyptLand.jpeg', titleKey: 'Egypt', subtitleKey: 'EgyptDesc', flagSrc: 'img/egyptFlag.png', delay: '0.5s' },
        { imgSrc: 'img/uzbekLand.webp', titleKey: 'Uzbekistan', subtitleKey: 'UzbekistanDesc', flagSrc: 'img/uzbekFlag.jpg', delay: '0.7s' },
        { imgSrc: 'img/turkeyLand.webp', titleKey: 'Türkiye', subtitleKey: 'TürkiyeDesc', flagSrc: 'img/turkeyFlag.jpg', delay: '0.9s' },
        { imgSrc: 'img/nigeriaLand.webp', titleKey: 'Nigeria', subtitleKey: 'NigeriaDesc', flagSrc: 'img/nigeriaFlag.jpg', delay: '1.1s' },
    ];

    return (
        <>
            <div className="container-fluid bg-light py-6" id="available">
                <div className="container">
                    <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
                        <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">
                            {translations.OurPresence}
                        </small>
                        <h1 className="display-10 mb-5">{translations.IndiaTo}</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-12 wow bounceInUp" data-wow-delay="0.1s">
                            <img className="animated bounceInDown img-fluid" src={map} alt="Logo" style={{ width: '100%', height: 'auto' }} />
                        </div>
                        {cardsData.map((card, index) => (
                            <Card key={index} {...card} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Availability;
