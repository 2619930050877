import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../context/LanguageContex';
import { CountryContext } from '../context/CountryContext';
import './Header.css'; 
import logo from './../assets/logo_resized.png';

function Header() {
  const { translations, changeLanguage } = useContext(LanguageContext);
  const { changeCountryLanguage } = useContext(CountryContext);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    changeCountryLanguage(lang);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll('.nav-item.nav-link');
    const navCollapse = document.getElementById('navbarCollapse');

    navLinks.forEach(link => {
      link.addEventListener('click', () => {
        if (navCollapse.classList.contains('show')) {
          navCollapse.classList.remove('show');
        }
      });
    });

    return () => {
      navLinks.forEach(link => {
        link.removeEventListener('click', () => {
          if (navCollapse.classList.contains('show')) {
            navCollapse.classList.remove('show');
          }
        });
      });
    };
  }, []);

  return (
    <>
      <div className={`container-fluid fixed-top ${isScrolled ? 'bg-white' : 'bg-transparent'}`}> {/* Dynamic class */}
        <div className="container">
          <nav className="navbar navbar-light navbar-expand-lg py-2"> {/* Reduced padding */}
            <a href="index.html" className="navbar-brand">
              <img src={logo} alt="Logo" style={{ width: '50px', height: '50px' }} />
            </a>
            <button className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="fa fa-bars text-primary" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <a href="#hero" className="nav-item nav-link" data-key="home">{translations.home}</a>
                <a href="#about" className="nav-item nav-link" data-key="about">{translations.about}</a>
                <a href="#available" className="nav-item nav-link" data-key="available">{translations.available}</a>
                <a href="#services" className="nav-item nav-link" data-key="services">{translations.services}</a>
                <a href="#contact" className="nav-item nav-link" data-key="contact">{translations.contact}</a>
                <div className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle lang-dropdown" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {translations.language}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" href="#" onClick={() => handleLanguageChange('en')}>English</a></li>
                    <li><a className="dropdown-item" href="#" onClick={() => handleLanguageChange('es')}>Español</a></li>
                    <li><a className="dropdown-item" href="#" onClick={() => handleLanguageChange('ar')}>العربية</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
