import React, { useContext } from 'react';
import { CountryContext } from '../context/CountryContext';

const ProductItem = ({ iconClass, titleKey, descriptionKey, delay }) => {
  const { translations } = useContext(CountryContext);

  return (
    <div className={`col-lg-3 col-md-6 col-sm-12 wow bounceInUp`} data-wow-delay={delay}>
      <div className="bg-light rounded service-item">
        <div className="service-content d-flex align-items-center justify-content-center p-4">
          <div className="service-content-icon text-center">
            <i className={`fas ${iconClass} fa-7x text-primary mb-4`} />
            <h4 className="mb-3">{translations[titleKey]}</h4>
            <p className="mb-4">{translations[descriptionKey]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function Products() {
  const { translations } = useContext(CountryContext);
  const products = [
    { iconClass: 'fa-lemon', titleKey: 'CoconutProducts', descriptionKey: 'CoconutProductsDesc', delay: '0.1s' },
    { iconClass: 'fa-mortar-pestle', titleKey: 'SpiceBlends', descriptionKey: 'SpiceBlendsDesc', delay: '0.2s' },
    { iconClass: 'fa-pepper-hot', titleKey: 'IndianSpices', descriptionKey: 'IndianSpicesDesc', delay: '0.3s' },
    { iconClass: 'fa-wheat-awn', titleKey: 'DryFruits', descriptionKey: 'DryFruitsDesc', delay: '0.4s' },
    { iconClass: 'fa-apple-alt', titleKey: 'Fruits', descriptionKey: 'FruitsDesc', delay: '0.5s' },
    { iconClass: 'fa-carrot', titleKey: 'Vegetables', descriptionKey: 'VegetablesDesc', delay: '0.6s' },
    { iconClass: 'fa-tshirt', titleKey: 'Garments', descriptionKey: 'GarmentsDesc', delay: '0.7s' },
    { iconClass: 'fa-puzzle-piece', titleKey: 'IndianHandicrafts', descriptionKey: 'IndianHandicraftsDesc', delay: '0.8s' },
  ];

  return (
    <>
      <div className="container-fluid service py-6" id="services">
        <div className="container">
          <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
            <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">
              {translations.OurProducts}
            </small>
            <h1 className="display-5 mb-5">{translations.WhatWeOffer}</h1>
          </div>
          <div className="row g-4">
            {products.map((product, index) => (
              <ProductItem
                key={index}
                iconClass={product.iconClass}
                titleKey={product.titleKey}
                descriptionKey={product.descriptionKey}
                delay={product.delay}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
