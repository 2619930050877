import React, { useState, useEffect } from 'react';
import About from './Components/About';
import Availability from './Components/Availability';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import Products from './Components/Products';
import whatsappIcon from './assets/whatsapp.png'
import { CountryProvider } from './context/CountryContext';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust the delay as needed
  }, []);

  return (
    <CountryProvider>
      <div className="App">
        {loading ? (
          <div id="spinner"
            className="show w-100 vh-100 bg-white position-fixed translate-middle top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner-grow text-primary" role="status"></div>
          </div>
        ) : (
          <>
            <Header></Header>
            <Home></Home>
            <About></About>
            <Availability></Availability>
            <Products></Products>
            <Footer></Footer>
            <a
              href="https://wa.me/+34627933049"
              className="whatsapp-float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img 
                src={whatsappIcon} 
                alt="WhatsApp" 
                className="whatsapp-icon" 
                style={{ width: '30px', height: '30px' }} // Set the desired width and height
              />
            </a>
          </>
        )}
      </div>
    </CountryProvider>
  );
}



export default App;
