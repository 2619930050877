import React, { createContext, useState } from 'react';

const CountryContext = createContext();

const countryTranslations = {
  en: {
    Spain: 'Spain',
    Morocco: 'Morocco',
    Egypt: 'Egypt',
    Uzbekistan: 'Uzbekistan',
    Türkiye: 'Türkiye',
    Nigeria: 'Nigeria',
    SpainDesc: 'The finest of Spanish goods with a touch of Mediterranean flair.',
    MoroccoDesc: 'Bridging trade between the Atlantic and the Sahara.',
    EgyptDesc: 'Ancient Egyptian trade routes- the best from the Nile to the world.',
    UzbekistanDesc: 'The silk road legacy to modern trade routes.',
    TürkiyeDesc: 'Connecting East and West - where tradition meets innovation.',
    NigeriaDesc: 'Unlocking Africa’s potential- Projecting Nigeria’s rich resources.',
    CoconutProducts: 'Coconut Products',
    CoconutProductsDesc: 'Explore our diverse range of coconut-based products.',
    SpiceBlends: 'Spice Blends',
    SpiceBlendsDesc: 'Authentic spice blends for an exquisite South Indian culinary experience.',
    IndianSpices: 'Indian Spices',
    IndianSpicesDesc: 'Renowned Indian spices that enhance the gastronomy of your food style.',
    DryFruits: 'Dry Fruits',
    DryFruitsDesc: 'Premium quality dry fruits sourced from the finest orchards.',
    Fruits: 'Fruits',
    FruitsDesc: 'A wide selection of fresh and juicy fruits for a healthy lifestyle.',
    Vegetables: 'Vegetables',
    VegetablesDesc: 'Fresh and organic vegetables directly from local farms.',
    Garments: 'Garments',
    GarmentsDesc: 'Traditional and stylish garments with fine quality fabrics.',
    IndianHandicrafts: 'Indian Handicrafts',
    IndianHandicraftsDesc: 'Exquisite handicrafts showcasing India\'s rich cultural and traditional heritage.',
    WhatWeOffer: 'What We Offer',
    OurProducts: 'Our Products',
    OurPresence: 'Our Presence',
    IndiaTo: 'India to..'
  },
  es: {
    Spain: 'España',
    Morocco: 'Marruecos',
    Egypt: 'Egipto',
    Uzbekistan: 'Uzbekistán',
    Türkiye: 'Turquía',
    Nigeria: 'Nigeria',
    SpainDesc: 'Los mejores productos españoles con un toque mediterráneo.',
    MoroccoDesc: 'Uniendo el comercio entre el Atlántico y el Sahara.',
    EgyptDesc: 'Rutas comerciales egipcias antiguas: lo mejor del Nilo al mundo.',
    UzbekistanDesc: 'El legado de la ruta de la seda a las rutas comerciales modernas.',
    TürkiyeDesc: 'Conectando Oriente y Occidente - donde la tradición se encuentra con la innovación.',
    NigeriaDesc: 'Desbloqueando el potencial de África - Proyectando los ricos recursos de Nigeria.',
    CoconutProducts: 'Productos de Coco',
    CoconutProductsDesc: 'Explore nuestra diversa gama de productos a base de coco.',
    SpiceBlends: 'Mezclas de Especias',
    SpiceBlendsDesc: 'Mezclas de especias auténticas para una experiencia culinaria del sur de la India.',
    IndianSpices: 'Especias Indias',
    IndianSpicesDesc: 'Especias indias reconocidas que mejoran la gastronomía de su estilo de comida.',
    DryFruits: 'Frutos Secos',
    DryFruitsDesc: 'Frutos secos de calidad premium obtenidos de los mejores huertos.',
    Fruits: 'Frutas',
    FruitsDesc: 'Una amplia selección de frutas frescas y jugosas para un estilo de vida saludable.',
    Vegetables: 'Verduras',
    VegetablesDesc: 'Verduras frescas y orgánicas directamente de granjas locales.',
    Garments: 'Prendas',
    GarmentsDesc: 'Prendas tradicionales y elegantes con telas de calidad fina.',
    IndianHandicrafts: 'Artesanías Indias',
    IndianHandicraftsDesc: 'Artesanías exquisitas que muestran la rica herencia cultural y tradicional de la India.',
    WhatWeOffer: 'Lo Que Ofrecemos',
    OurProducts: 'Nuestros Productos',
    OurPresence: 'Nuestra Presencia',
    IndiaTo: 'India a..'
  },
  ar: {
    Spain: 'إسبانيا',
    Morocco: 'المغرب',
    Egypt: 'مصر',
    Uzbekistan: 'أوزبكستان',
    Türkiye: 'تركيا',
    Nigeria: 'نيجيريا',
    SpainDesc: 'أفضل البضائع الإسبانية بلمسة متوسطية.',
    MoroccoDesc: 'جسر التجارة بين المحيط الأطلسي والصحراء.',
    EgyptDesc: 'طرق التجارة المصرية القديمة - الأفضل من النيل إلى العالم.',
    UzbekistanDesc: 'إرث طريق الحرير إلى طرق التجارة الحديثة.',
    TürkiyeDesc: 'ربط الشرق والغرب - حيث تلتقي التقاليد بالابتكار.',
    NigeriaDesc: 'إطلاق العنان لإمكانات إفريقيا - عرض الموارد الغنية لنيجيريا.',
    CoconutProducts: 'منتجات جوز الهند',
    CoconutProductsDesc: 'استكشف مجموعتنا المتنوعة من المنتجات القائمة على جوز الهند.',
    SpiceBlends: 'خلطات التوابل',
    SpiceBlendsDesc: 'خلطات التوابل الأصيلة لتجربة طهي جنوب الهند الفاخرة.',
    IndianSpices: 'التوابل الهندية',
    IndianSpicesDesc: 'التوابل الهندية الشهير�� التي تعزز فن الطهي الخاص بك.',
    DryFruits: 'الفواكه المجففة',
    DryFruitsDesc: 'فواكه مجففة عالية الجودة من أفضل البساتين.',
    Fruits: 'الفواكه',
    FruitsDesc: 'مجموعة واسعة من الفواكه الطازجة والعصرية لنمط حياة صحي.',
    Vegetables: 'الخضروات',
    VegetablesDesc: 'الخضروات الطازجة والعضوية مباشرة من المزارع المحلية.',
    Garments: 'الملابس',
    GarmentsDesc: 'ملابس تقليدية وأنيقة بأقمشة ذات جودة عالية.',
    IndianHandicrafts: 'الحرف اليدوية الهندية',
    IndianHandicraftsDesc: 'الحرف اليدوية الرائعة التي تعرض التراث الثقافي والتقليدي الغني للهند.',
    WhatWeOffer: 'ما نقدمه',
    OurProducts: 'منتجاتنا',
    OurPresence: 'وجودنا',
    IndiaTo: 'الهند إلى..'
  }
};

const CountryProvider = ({ children, initialLanguage = 'en' }) => {
  const [countryLanguage, setCountryLanguage] = useState(initialLanguage);

  const changeCountryLanguage = (lang) => {
    setCountryLanguage(lang);
  };

  return (
    <CountryContext.Provider value={{ countryLanguage, changeCountryLanguage, translations: countryTranslations[countryLanguage] }}>
      {children}
    </CountryContext.Provider>
  );
};

export { CountryContext, CountryProvider };
